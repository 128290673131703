import { Dashboard, useUppyEvent } from '@uppy/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Uppy from '@uppy/core';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DownloadGate } from '../../core/_models';
import AwsS3 from '@uppy/aws-s3';
import { confirmMediaUpload, createMedia } from '../../../media/core/_requests';
import { firstValueFrom, from, mergeMap, of } from 'rxjs';
import { AxiosResponse } from 'axios';
import { Media, UploadMedia } from '../../../media/core/_models';
import { useEffect, useState } from 'react';

import '../styles/Step1.css';
import { useRecoilValue } from 'recoil';
import { musicalCategoriesListQuery } from '../../core/MusicalCategories';
import { createGate } from '../../core/_requests';
import { useNavigate } from 'react-router-dom';

const downloadGateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  musical_genre_id: Yup.number().required('Genre is required'),
  description: Yup.string()
    .nullable()
    .transform((_, value) => {
      return value === '' ? null : value;
    }),
  artwork_id: Yup.number().required()
});

const initialValues = {
  title: '',
  description: null,
  musical_genre_id: null
};

export function Step1() {
  const [uppy] = useState(() =>
    new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
      },
      debug: true
    }).use(AwsS3, {
      getUploadParameters: (file: any) => {
        return firstValueFrom(
          from(createMedia(file.name, file.type, true)).pipe(
            mergeMap((media: AxiosResponse<UploadMedia>, index) => {
              file.meta['media_id'] = media.data.media.id;

              return of({
                method: 'POST',
                url: media.data.upload_url.url,
                fields: media.data.upload_url.fields,
                headers: {}
              });
            })
          )
        );
      }
    })
  );

  useUppyEvent(uppy, 'upload-success', (result: any) => {
    if (result) {
      confirmMediaUpload(result.meta.media_id).then((res) => {
        setArtwork(res.data);
        formik.setFieldValue('artwork_id', res.data.id);
      });
    }
  });

  const navigate = useNavigate();
  const [artwork, setArtwork] = useState<Media | null>(null);
  const musicalCategories = useRecoilValue(musicalCategoriesListQuery);
  const [loading, setLoading] = useState(false);

  const formik = useFormik<DownloadGate>({
    initialValues,
    validationSchema: downloadGateSchema,
    onSubmit: async (values, { setStatus }) => {
      const castedValues = downloadGateSchema.cast(values);

      if (artwork == null) {
        setStatus('Artwork is required.');
        return;
      }

      try {
        const { data: downloadGate } = await createGate(castedValues);
        navigate('/gates/new/' + downloadGate.id + '/files');
        setLoading(false);
      } catch (error) {
        console.error(error);

        setLoading(false);
        setStatus('An error occured, please check your account details again.');
      }
    }
  });

  function removeArtwork() {
    setArtwork(null);
    uppy.removeFile(uppy.getFiles()[0].id);
    formik.setFieldValue('artwork_id', null);
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form mx-auto">
      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="row mb-20">
        <div className="col-4">
          <p className="fs-2 fw-bold mb-10">Artwork</p>
          {!artwork && (
            <Dashboard
              width="100%"
              height="100%"
              note="Drop your artwork here"
              uppy={uppy}
              hideRetryButton={true}
              hideCancelButton={true}
              hidePauseResumeButton={true}
              hideUploadButton={true}></Dashboard>
          )}

          {artwork != null && (
            <div
              className="image-input image-input-outline w-100 h-100"
              style={{ backgroundImage: 'url(' + artwork.public_url + ')' }}>
              <div
                className="image-input-wrapper w-100 h-100"
                style={{
                  backgroundImage: 'url(' + artwork.public_url + ')'
                }}></div>
              <span
                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                data-kt-image-input-action="remove"
                aria-label="Remove artwork"
                data-kt-initialized="1"
                onClick={removeArtwork}>
                <i className="fa fa-trash"></i>
              </span>
            </div>
          )}
        </div>
        <div className="col-8">
          <p className="fs-2 fw-bold mb-10">Informations</p>

          <div className="row mb-6">
            <label className="col-lg-3 col-form-label required fw-bold fs-6">Title</label>

            <div className="col-lg-9 fv-row">
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="Title"
                {...formik.getFieldProps('title')}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              )}
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-3 col-form-label required fw-bold fs-6">Genre</label>

            <div className="col-lg-9 fv-row">
              <select
                className="form-select form-select-solid"
                {...formik.getFieldProps('musical_genre_id')}>
                <option value="">Select genre</option>
                {musicalCategories.length > 0 &&
                  musicalCategories.map((category) => (
                    <optgroup label={category.name}>
                      {category.genres.map((genre) => (
                        <option value={genre.id}>{genre.name}</option>
                      ))}
                    </optgroup>
                  ))}
              </select>
              {formik.touched.musical_genre_id && formik.errors.musical_genre_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.musical_genre_id}</div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="mb-10">
              <label htmlFor="exampleFormControlInput1" className="form-label fs-6 fw-bold mb-5">
                Description
              </label>
              <textarea
                className="form-control form-control-solid"
                placeholder="Short description of your download gate"
                {...formik.getFieldProps('description')}></textarea>

              {formik.touched.description && formik.errors.description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.description}</div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={formik.isSubmitting || !formik.isValid}>
                {!loading && <span className="indicator-label">Continue</span>}
                {loading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
