/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { DownloadGate } from '../core/_models';

export function DownloadGateListItem({ gate }: { gate: DownloadGate }) {
  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50px me-5">
              <span className="symbol-label bg-light">
                {gate.artwork && gate.artwork.public_url ? (
                  <img src={gate.artwork.public_url} className="h-100 align-self-end" alt="" />
                ) : (
                  <img
                    src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                    className="h-100 align-self-end"
                    alt=""
                  />
                )}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <Link
                to={'/gates/view/' + gate.id + '/'}
                className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                {gate.title}
              </Link>
              <span className="text-muted fw-semibold text-muted d-block fs-7">
                {gate.musical_genre ? gate.musical_genre.name : 'N/A'}
              </span>
            </div>
          </div>
        </td>
        <td>
          <a href="#" className="text-primary fw-bold d-block mb-1 fs-6">
            {gate.unique_view_count} unique views
          </a>
        </td>
        <td>
          <a href="#" className="text-danger fw-bold d-block mb-1 fs-6">
            {gate.download_count} downloads
          </a>
        </td>
        <td>
          <span className="text-success fw-bold d-block mb-1 fs-6">{gate.conversion_rate} %</span>
        </td>
        <td>
          <Link
            to={'/gates/view/' + gate.id + '/'}
            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2">
            Details
          </Link>
        </td>
      </tr>
    </>
  );
}
