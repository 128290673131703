/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLayout } from '../core';

const Footer: FC = () => {
  const { classes } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row flex-stack`}>
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-gray-400 fw-bold me-1">
            &copy; Copyright 2017 - {moment().format('YYYY')} - fangate.eu - All rights reserved.
            Version: {process.env.REACT_APP_VERSION} / Build: {process.env.REACT_APP_BUILD}
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Menu */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <Link to="/dashboard" className="menu-link px-2">
              Dashboard
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/gates" className="menu-link px-2">
              My gates
            </Link>
          </li>
          <li className="menu-item">
            <a href="https://help.fangate.eu" target="_blank" className="menu-link px-2">
              Get help
            </a>
          </li>
        </ul>
        {/* end::Menu */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
