/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, useRef, useState } from 'react';
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ListRowSkeleton } from '../core/_helpers';
import { DownloadGateList } from './DownloadGateList';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { gateQuery } from '../core/Gate';
import { ListGateStep } from './ListGateStep';
import { KTSVG } from '../../../../_metronic/helpers';
import { Overview } from './view_components/Overview';
import Clipboard from 'react-clipboard.js';
import { Edit } from './view_components/Edit';
import { getGate, getGateMetrics } from '../core/_requests';
import { useQuery } from 'react-query';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Gates',
    path: '/gates',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

export function View() {
  const { id } = useParams();

  const { data: downloadGate, status } = useQuery(['gate', id], async ({ queryKey }) => {
    const { data: downloadGate } = await getGate(parseInt(queryKey[1]!));
    return downloadGate;
  });
  // TODO: Use status from useQuery to show loading skeleton

  const tooltipTarget = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{downloadGate!.title}</PageTitle>
      <div className="card mb-6 mb-xl-9">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
              <img className="w-100 h-100" src={downloadGate?.artwork?.public_url} alt="image" />
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <p className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">
                      {downloadGate?.title}
                    </p>
                    {/* <span className="badge badge-light-success me-auto">In Progress</span> */}
                  </div>
                  {downloadGate?.description && (
                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                      {downloadGate?.description}
                    </div>
                  )}

                  <div className="d-flex my-3">
                    <input
                      className="form-control form-control-sm form-control-solid me-3"
                      style={{ width: '250px' }}
                      value={downloadGate?.public_url}
                      readOnly
                      ref={tooltipTarget}
                    />
                    <Clipboard
                      data-clipboard-text={downloadGate?.public_url}
                      className="btn btn-sm btn-light fw-bold flex-shrink-0 btn-icon me-3"
                      onSuccess={() => {
                        setShowTooltip(true);
                        setTimeout(() => {
                          setShowTooltip(false);
                        }, 1500);
                      }}>
                      <KTSVG
                        path="/media/icons/duotune/general/gen028.svg"
                        className="svg-icon svg-icon-3"
                      />
                    </Clipboard>
                    <Overlay target={tooltipTarget.current} show={showTooltip} placement="bottom">
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          Copied!
                        </Tooltip>
                      )}
                    </Overlay>

                    <a
                      className="btn btn-sm btn-light fw-bold flex-shrink-0 btn-icon me-3"
                      href={downloadGate?.public_url}
                      target="_blank">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr095.svg"
                        className="svg-icon svg-icon-3"
                      />
                    </a>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-download">Download attached file</Tooltip>}>
                      <a
                        className="btn btn-sm btn-light fw-bold flex-shrink-0 btn-icon"
                        href={downloadGate?.attached_file?.download_url}
                        target="_blank">
                        <KTSVG
                          path="/media/icons/duotune/files/fil021.svg"
                          className="svg-icon svg-icon-3"
                        />
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                {/* <div className="d-flex mb-4">
                  <a
                    href="#"
                    className="btn btn-sm btn-bg-light btn-active-color-primary me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_users_search">
                    Add User
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm btn-primary me-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_new_target">
                    Add Target
                  </a>
                  <div className="me-0">
                    <button
                      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end">
                      <i className="bi bi-three-dots fs-3"></i>
                    </button>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                      data-kt-menu="true">
                      <div className="menu-item px-3">
                        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                          Payments
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="d-flex flex-wrap justify-content-start">
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-4 fw-bold">
                        {new Date(downloadGate!.created!).toLocaleDateString('en-gb', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </div>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">Creation date</div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr066.svg"
                        className="svg-icon svg-icon-3 svg-icon-success me-2"
                      />
                      <div className="fs-4 fw-bold">{downloadGate?.view_count}</div>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">Page views</div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr066.svg"
                        className="svg-icon svg-icon-3 svg-icon-success me-2"
                      />
                      <div className="fs-4 fw-bold">{downloadGate?.unique_view_count}</div>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">Unique views</div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr066.svg"
                        className="svg-icon svg-icon-3 svg-icon-success me-2"
                      />
                      <div className="fs-4 fw-bold">{downloadGate?.download_count}</div>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">Total downloads</div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <KTSVG
                        path="/media/icons/duotune/general/gen031.svg"
                        className="svg-icon svg-icon-3 svg-icon-success me-2"
                      />
                      <div className="fs-4 fw-bold">{downloadGate?.conversion_rate} %</div>
                    </div>
                    <div className="fw-semibold fs-6 text-gray-400">Conversion rate</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <li className="nav-item">
              <NavLink className="nav-link text-active-primary py-5 me-6" to="overview">
                Overview
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-active-primary py-5 me-6" to="steps">
                Gate steps
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-active-primary py-5 me-6" to="edit">
                Edit informations
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link text-active-primary py-5 me-6" to="premium">
                <KTSVG
                  path="/media/icons/duotune/general/gen003.svg"
                  className="svg-icon svg-icon-3 me-2"
                />
                Premium features
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>

      <Routes>
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<Overview downloadGate={downloadGate!} />} />
        <Route
          path="steps"
          element={
            <>
              <div className="card mt-10">
                {/* begin::Header */}
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Download gate steps</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>subtitle here</span> */}
                  </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className="card-body py-3">
                  <div className="row">
                    <ListGateStep downloadGate={downloadGate!} title={false}></ListGateStep>
                  </div>
                </div>
                {/* begin::Body */}
              </div>
            </>
          }
        />
        <Route path="edit" element={<Edit downloadGate={downloadGate!} />} />
      </Routes>
    </>
  );
}
