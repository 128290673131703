import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { confirmOAuth } from './core/_requests';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function PublicOAuthRedirectPage() {
  const navigate = useNavigate();
  const query = useQuery();
  const { oauth_provider } = useParams();

  useEffect(() => {
    const _ = async () => {
      // Get provider from local storage & download session id
      const download_session_oauth_provider = localStorage.getItem(
        'download_session_oauth_provider'
      );
      const download_session_uid = localStorage.getItem('download_session_uid');

      if (download_session_oauth_provider != oauth_provider || download_session_uid == null) {
        alert('Invalid OAuth provider');
        return;
      }

      // Send to API OAuth data
      const result = await confirmOAuth(download_session_uid!, oauth_provider, query.get('code')!);

      // Redirect to download session page
      navigate('/download/' + download_session_uid);
    };

    _().catch(console.error);
  }, []);

  return (
    <>
      <div className="splash-screen">
        <img
          src="/media/logos/logo-horizontal-black.png"
          className="theme-light-show"
          alt="Fangate logo"
        />
        <img
          src="/media/logos/logo-horizontal-white.png"
          className="theme-dark-show"
          alt="Fangate logo"
        />
        <span>Loading ...</span>
      </div>
    </>
  );
}
