import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './css/PublicGatePage.scss';
import './css/fontSizes.scss';
import './css/PublicDownloadSessionPage.scss';

import { useQuery } from 'react-query';
import {
  generateDownloadSessionOAuthRedirect,
  getDownloadSession,
  getDownloadSessionDownloadLink,
  postLogSessionAction
} from './core/_requests';
import { useEffect, useState } from 'react';

export function PublicDownloadSessionPage() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [instagramLoading, setInstagramLoading] = useState(false);

  const { data: downloadSession, refetch: refetchSession } = useQuery(
    ['downloadSessionQuery', uid],
    async ({ queryKey }) => {
      const { data: gate } = await getDownloadSession(queryKey[1]!);
      return gate;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  async function OAuthRedirect(oauth_provider: string) {
    ReactGA.event({
      category: 'Download Session',
      action: 'OAuth Redirect',
      label: oauth_provider
    });

    const { data: url } = await generateDownloadSessionOAuthRedirect(uid!, oauth_provider);

    // Set UID & oauth_provider to local storage
    localStorage.setItem('download_session_uid', uid!);
    localStorage.setItem('download_session_oauth_provider', oauth_provider);

    window.location = url.url;
  }

  function logSessionAction(action: string) {
    ReactGA.event({
      category: 'Download Session',
      action: action
    });

    if (downloadSession) {
      postLogSessionAction(downloadSession.uid!, action);
      refetchSession();
    }
  }

  useEffect(() => {
    async function _() {
      ReactGA.event({
        category: 'Download Session',
        action: 'Finished Download'
      });
      const { data: url } = await getDownloadSessionDownloadLink(uid!);
      window.location.href = url.url;
      setSuccess(true);
    }

    if (
      downloadSession?.missing_oauth_providers!.length! == 0 &&
      Object.keys(downloadSession!.extra_steps).length == 0
    ) {
      setTimeout(() => {
        _().catch((err) => {
          console.error(err);
          setError(true);
        });
      }, 3000);
    }
  }, [downloadSession]);

  return (
    <>
      <div
        className="background"
        style={{
          backgroundImage: 'url("' + downloadSession!.download_gate.artwork_url + '")'
        }}></div>
      <div className="background-overlay"></div>
      <div className="container-fluid">
        <div className="row mt-15">
          <div className="col-md-12 text-center">
            <img src="/media/logo-horizontal-white.png" width={300} />
          </div>
        </div>

        <div className="row mt-20">
          <div className="col-md-4 align-self-center">
            <img src={downloadSession!.download_gate.artwork_url!} className="img-fluid" />
          </div>
          <div className="col-md-8 text-center">
            <h2 className="white">{downloadSession!.download_gate.title}</h2>

            <div className="mt-5 white" style={{ fontSize: 18 }}>
              To download this track for free, you need to support the artist and the platform by
              logging in with all of the following method(s) :
            </div>

            {downloadSession?.extra_steps &&
              Object.keys(downloadSession?.extra_steps).includes('instagram_visit') == true && (
                <>
                  <div className="row mt-10">
                    <div className="col text-center">
                      <a
                        onClick={() => {
                          setInstagramLoading(true);
                          setTimeout(() => {
                            setInstagramLoading(false);
                            logSessionAction('instagram_visit');
                          }, 5000);
                        }}
                        href={downloadSession!.extra_steps.instagram_visit.url}
                        target="_blank"
                        className="btn btn-square btn-instagram loading"
                        style={{ fontSize: 18 }}>
                        {instagramLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                            <span className="ms-3">Checking followers...</span>
                          </>
                        ) : (
                          <>
                            <i className="fab fa-instagram white fonts-26"></i>
                            <span className="ms-3">Follow on Instagram</span>
                          </>
                        )}
                      </a>
                    </div>
                  </div>
                </>
              )}

            {Object.keys(downloadSession!.extra_steps).length == 0 &&
              downloadSession?.missing_oauth_providers!.length! > 0 &&
              !error &&
              !success && (
                <>
                  {downloadSession?.missing_oauth_providers!.includes('spotify') && (
                    <div className="row mt-10">
                      <div className="col text-center">
                        <a
                          onClick={() => OAuthRedirect('spotify')}
                          className="btn btn-square btn-spotify"
                          style={{ fontSize: 18 }}>
                          <i className="fab fa-spotify white fonts-26"></i>
                          Spotify
                        </a>
                      </div>
                    </div>
                  )}

                  {downloadSession?.missing_oauth_providers!.includes('soundcloud') && (
                    <div className="row mt-10">
                      <div className="col text-center">
                        <a
                          onClick={() => OAuthRedirect('soundcloud')}
                          className="btn btn-square btn-soundcloud"
                          style={{ fontSize: 18 }}>
                          <i className="fab fa-soundcloud white fonts-26"></i>
                          Soundcloud
                        </a>
                      </div>
                    </div>
                  )}

                  {downloadSession?.missing_oauth_providers!.includes('twitter') && (
                    <div className="row mt-10">
                      <div className="col text-center">
                        <a
                          onClick={() => OAuthRedirect('twitter')}
                          className="btn btn-square btn-twitter"
                          style={{ fontSize: 18 }}>
                          <i className="fab fa-twitter white fonts-26"></i>
                          Twitter
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}

            {downloadSession?.missing_oauth_providers!.length! == 0 &&
              Object.keys(downloadSession!.extra_steps).length == 0 &&
              !error &&
              !success && (
                <>
                  <div className="spinner-border text-light mt-10" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="mt-5 white" style={{ fontSize: 14, fontStyle: 'italic' }}>
                    Please wait while we are
                    <br />
                    generating your download link...
                  </div>
                </>
              )}

            {error && (
              <>
                <div className="row mt-10">
                  <div className="col text-center white fonts-18">
                    Unfortunately, we were unable to generate your download link.
                    <br />
                    It may happen if you already downloaded the track using this link or if the link
                    is expired.
                    <br />
                    <Link
                      className="btn btn-square btn-fg-white black mt-5"
                      to={'/tracks/' + downloadSession?.download_gate.slug}>
                      START OVER
                    </Link>
                  </div>
                </div>
              </>
            )}

            {success && (
              <div className="row mt-10">
                <div className="col text-center white fonts-18">
                  Thank you for downloading this content.
                  <br />
                  You can now close this window.
                </div>
                {downloadSession?.extra?.instagram_url && (
                  <div className="row mt-10">
                    <div className="col text-center">
                      <a
                        href={downloadSession?.extra?.instagram_url}
                        target="_blank"
                        className="btn btn-square btn-instagram"
                        style={{ fontSize: 18 }}>
                        <i className="fab fa-instagram white fonts-26"></i>
                        <span className="ms-3">Follow on Instagram</span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="row mt-20">
          <div className="col text-center">
            <p className="white fonts-18 " style={{ fontWeight: 'bold' }}>
              Fangate.eu, the first fangate service for artists Want to use our services ?
              <br />
              <Link
                to="/auth/registration"
                className="white text-decoration-underline"
                target="_blank">
                Create your account now ! It's free !
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
