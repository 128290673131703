import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './css/PublicGatePage.scss';
import { useQuery } from 'react-query';
import { createDownloadSession, getPublicDownloadGate, logVisit } from './core/_requests';
import { useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export function PublicGatePage() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { data: gate } = useQuery(['publicGateQuery', slug], async ({ queryKey }) => {
    const { data: gate } = await getPublicDownloadGate(queryKey[1]!);
    return gate;
  });

  async function createSession() {
    const { data: session } = await createDownloadSession(gate!.slug);
    if (window.location.hostname == 'app.fangate.eu') {
      navigate('/download/' + session.uid);
    } else {
      window.location.href = process.env.REACT_APP_PUBLIC_URL + '/download/' + session.uid;
    }
  }

  useEffect(() => {
    async function _() {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      await logVisit(slug!, visitorId, navigator.userAgent);
    }

    _().catch(console.error);
  }, []);

  return (
    <>
      <div
        className="background"
        style={{ backgroundImage: 'url("' + gate!.artwork_url + '")' }}></div>
      <div className="background-overlay"></div>
      <div className="container-fluid">
        <div className="row mt-15">
          <div className="col-md-12 text-center">
            <img src="/media/logo-horizontal-white.png" width={300} />
          </div>
        </div>

        <div className="row mt-20">
          <div className="col-md-4 align-self-center">
            <img src={gate!.artwork_url!} className="img-fluid" />
          </div>
          <div className="col-md-8 text-center align-self-center">
            <h2 className="white">{gate!.title}</h2>
            <div className="mt-10 white">{gate!.description}</div>
            <p className="mt-20">
              <a
                className="btn btn-fg-white btn-square fonts-24"
                onClick={() => {
                  ReactGA.event({
                    category: 'Download Session',
                    action: 'Initiated',
                    label: gate!.title
                  });
                  createSession();
                }}>
                FREE DOWNLOAD
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
