import { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { Outlet, useLocation } from 'react-router-dom';
import { MasterInit } from '../_metronic/layout/MasterInit';

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none');
  }
};

const App = () => {
  useEffect(() => {
    disableSplashScreen();
  }, []);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title
    });
  }, [location]);

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  );
};

export { App };
