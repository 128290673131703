import * as Yup from 'yup';
import Uppy from '@uppy/core';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { DownloadGate, Media } from '../../core/_models';
import AwsS3 from '@uppy/aws-s3';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';
import { musicalCategoriesListQuery } from '../../core/MusicalCategories';
import { checkGateSlugAvailability, updateGate } from '../../core/_requests';
import { firstValueFrom, from, mergeMap, of } from 'rxjs';
import { confirmMediaUpload, createMedia } from '../../../media/core/_requests';
import { UploadMedia } from '../../../media/core/_models';
import { AxiosResponse } from 'axios';
import { Dashboard, useUppyEvent } from '@uppy/react';
import { useDebounce } from '@uidotdev/usehooks';
import { useQueryClient } from 'react-query';

const downloadGateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  slug: Yup.string().required('URL is required'),
  musical_genre_id: Yup.number().required('Genre is required'),
  description: Yup.string()
    .nullable()
    .transform((_, value) => {
      return value === '' ? null : value;
    }),
  artwork_id: Yup.number().required()
});

export function Edit(props: { downloadGate: DownloadGate }) {
  const [uppy] = useState(() =>
    new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
      },
      debug: true
    }).use(AwsS3, {
      getUploadParameters: (file: any) => {
        return firstValueFrom(
          from(createMedia(file.name, file.type, true)).pipe(
            mergeMap((media: AxiosResponse<UploadMedia>, index) => {
              file.meta['media_id'] = media.data.media.id;

              return of({
                method: 'POST',
                url: media.data.upload_url.url,
                fields: media.data.upload_url.fields,
                headers: {}
              });
            })
          )
        );
      }
    })
  );

  useUppyEvent(uppy, 'upload-success', (result: any) => {
    if (result) {
      confirmMediaUpload(result.meta.media_id).then((res) => {
        setArtwork(res.data);
        formik.setFieldValue('artwork_id', res.data.id);
      });
    }
  });

  const [loading, setLoading] = useState(false);
  const [downloadGate, setDownloadGate] = useState<DownloadGate>(props.downloadGate);
  const [artwork, setArtwork] = useState<Media | null>(props.downloadGate.artwork!);

  const queryClient = useQueryClient();

  const [newSlug, setNewSlug] = useState<string | null>(null);
  const debouncedSlug = useDebounce(newSlug, 500);

  const musicalCategories = useRecoilValue(musicalCategoriesListQuery);

  function removeArtwork() {
    setArtwork(null);
    if (uppy.getFiles().length > 0) uppy.removeFile(uppy.getFiles()[0].id);
    formik.setFieldValue('artwork_id', null);
  }

  const formik = useFormik<DownloadGate>({
    initialValues: {
      title: downloadGate.title,
      slug: downloadGate.slug,
      description: downloadGate.description || '',
      musical_genre_id: downloadGate.musical_genre?.id,
      artwork_id: downloadGate.artwork?.id
    },
    validationSchema: downloadGateSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      try {
        const { data: updatedDownloadGate } = await updateGate(downloadGate.id!, {
          title: values.title,
          slug: values.slug,
          description: values.description === '' ? null : values.description,
          musical_genre_id: values.musical_genre_id,
          artwork_id: values.artwork_id
        });
        setDownloadGate(updatedDownloadGate);
        setLoading(false);
        setStatus(null);
        queryClient.refetchQueries(['gate', downloadGate.id]);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setStatus('An error occured, please check your account details again.');
      }
    },
    validate: async (values) => {
      if (values.slug !== downloadGate.slug) {
        const { data: result } = await checkGateSlugAvailability(downloadGate.id!, values.slug);
        if (!result.available) {
          return { slug: 'This URL is already taken' };
        }
      }
    }
  });

  return (
    <>
      <div className="card mt-10">
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">Edit informations</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>subtitle here</span> */}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          <div className="row">
            <div id="kt_account_profile_details" className="collapse show">
              <form onSubmit={formik.handleSubmit} noValidate className="form">
                <div className="card-body border-top p-9">
                  {formik.status && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                  )}

                  <div className="row mb-10">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Visual</label>

                    <div className="col-lg-9">
                      {!artwork && (
                        <Dashboard
                          width="100%"
                          height="250px"
                          note="Drop your artwork here"
                          uppy={uppy}
                          hideRetryButton={true}
                          hideCancelButton={true}
                          hidePauseResumeButton={true}
                          hideUploadButton={true}></Dashboard>
                      )}

                      {artwork != null && (
                        <div className="image-input image-input-outline w-250px h-250px">
                          <div
                            className="image-input-wrapper w-250px h-250px"
                            style={{
                              backgroundImage: 'url(' + artwork.public_url + ')'
                            }}></div>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="remove"
                            aria-label="Remove artwork"
                            data-kt-initialized="1"
                            onClick={removeArtwork}>
                            <i className="fa fa-trash"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-10">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Title</label>

                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-lg fv-row">
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Title"
                            {...formik.getFieldProps('title')}
                          />
                          {formik.touched.title && formik.errors.title && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.title}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-10">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">URL</label>

                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-lg fv-row">
                          <div className="input-group input-group-solid mb-5">
                            <span className="input-group-text">
                              {downloadGate.public_url.replace(downloadGate.slug!, '')}
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              {...formik.getFieldProps('slug')}
                            />
                          </div>

                          {formik.touched.slug && formik.errors.slug && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.slug}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-10">
                    <label className="col-lg-3 col-form-label required fw-bold fs-6">Genre</label>

                    <div className="col-lg-9 fv-row">
                      <select
                        className="form-select form-select-solid"
                        {...formik.getFieldProps('musical_genre_id')}>
                        <option value="">Select genre</option>
                        {musicalCategories.length > 0 &&
                          musicalCategories.map((category) => (
                            <optgroup label={category.name} key={category.name}>
                              {category.genres.map((genre) => (
                                <option value={genre.id} key={genre.id}>
                                  {genre.name}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                      </select>
                      {formik.touched.musical_genre_id && formik.errors.musical_genre_id && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.musical_genre_id}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-10">
                    <div className="mb-10">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fs-6 fw-bold mb-5">
                        Description :
                      </label>
                      <textarea
                        className="form-control form-control-solid"
                        placeholder="Short description of your download gate"
                        {...formik.getFieldProps('description')}></textarea>

                      {formik.touched.description && formik.errors.description && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.description}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading || !formik.isValid}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className="indicator-progress" style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
}
